<template>
  <div>
    <div class="flex justify-end mt-3 mb-6">
        <GridViewListView />
    </div>
    <Card>
      <template slot="outer">
        <PageTopOuter
            icon="emoji_objects"
            label="Special Materials"
         />
      </template>
      <CardBody class="p-3">
        <Table :items="specialMaterials" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single" >
            <md-table-cell md-label="GROUP ID">{{ item.fileId }}</md-table-cell>
            <md-table-cell md-label="FILE NAME" class="text-victoria">
                <img class="mr-2" src="/images/thumbnail2.png" alt="">
                {{ item.fileName }}
            </md-table-cell>
            <md-table-cell md-label="FILE TYPE">{{ item.fileType }}</md-table-cell>
            <md-table-cell md-label="CREATED BY">{{ item.createdBy }}</md-table-cell>
            <md-table-cell md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
            <md-table-cell md-label="DOWNLOAD ACCESS">
                <md-icon class="text-green">{{ item.downloadAccess ? 'check': ''}}</md-icon>
            </md-table-cell>
            <md-table-cell md-label="CREATED FOR">{{ item.createdFor }}</md-table-cell>
            <md-table-cell md-label="RELATED WITH">{{ item.relatedWith }}</md-table-cell>
            <span class="action">
                <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
                <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">edit</md-icon>
              </span>
          </template>
        </Table>
      </CardBody>
    </Card>
    <Dialog>
      <CreateNewMaterial />
    </Dialog>
  </div>
</template>

<script>
import { Card, CardBody, Table, Dialog } from "@/components";
import GridViewListView from "@/components/atom/GridViewListView";
import PageTopOuter from "@/components/molecule/PageTopOuter";
import CreateNewMaterial from "@/components/molecule/material/CreateNewMaterial";
import data from "@/data/material/data"

export default {
  data() {
    return {
      specialMaterials: data.specialMaterials
    };
  },
  components: {
    Card,
    Table,
    Dialog,
    CardBody,
    PageTopOuter,
    GridViewListView,
    CreateNewMaterial
  }
};
</script>
