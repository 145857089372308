<template>
  <div class="center">
    <router-link :to="{ name: gridPath }"><md-icon>apps</md-icon></router-link>
    <md-switch v-model="boolean" class="px-2 m-0"></md-switch>
    <router-link :to="{ name: listPath}"><md-icon>format_list_bulleted</md-icon></router-link>
  </div>
</template>

<script>
export default {
    data() {
        return {
            boolean: true
        }
    },
    props: {
      gridPath: {
        type: String,
        required: false
      },
      listPath: {
        type: String,
        required: false
      }
    }
};
</script>

<style></style>
