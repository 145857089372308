
const mainNavigation = [
  { id: 1, icon: 'folder_open', name: 'Materials Dashboard', file: 125, path: 'material.dashboard' },
  { id: 2, icon: 'group', name: 'Groups', file: 125, path: 'material.group' },
  { id: 3, icon: 'cast_for_education', name: 'Course Wise Material', file: 125, path: 'material.courseWise' },
  { id: 4, icon: 'emoji_objects', name: 'Special Materials', file: 125, path: 'material.special' },
  { id: 5, icon: 'psychology', name: 'PTE Word List', file: 125, path: 'material.wordList' }
]

const wordList = [];
for (let i = 0; i < 15; i++) {
  wordList.push({
      id: i,
      word: "Shawna Dubbin",
      related: "RL, DI",
      partsOfSpeech: "Adjective",
      phonetics: "O-pan",
      pronunciation: "flaticon-volume",
      meaning: "Free",
      synonyms: "Unclose",
      antonyms: "Close",
    });
}

const stMaterialList = [];
for (let i = 0; i < 15; i++) {
  stMaterialList.push({
    id: i,
    fId: 'Q12' + i,
    title: 'Population of Canada',
    fileType: 'Docs',
    usedFor: 'PTE 2 | PRACTICE 4 | MOCK 2',
    createdAt: '25 May, 2020, 9:15pm',
    createdBy: 'Kazi Shahin',
    status: 'Active'
  });
}

const groupMaterials = [];
for (let i = 0; i < 15; i++) {
  groupMaterials.push({
    id: i,
    groupId: 'M12' + i,
    fileName: 'Listening Group',
    createdBy: 'Kazi Shahin',
    createdAt: '25 May, 2020, 9:15pm',
    downloadAccess: true,
    NoOfFile: 5,
    materialRelatedWith: 'RO, DI, RL'
  });
}

const specialMaterials = [];
for (let i = 0; i < 15; i++) {
  specialMaterials.push({
    id: i,
    fileId: 'M12' + i,
    fileName: 'Listening Group',
    fileType: 'Docx',
    createdBy: 'Kazi Shahin',
    createdAt: '25 May, 2020, 9:15pm',
    downloadAccess: true,
    createdFor: 'RO, DI, RL',
    relatedWith: 'Mock'
  });
}

const courseWiseMaterials = [];
for (let i = 0; i < 15; i++) {
  courseWiseMaterials.push({
    id: i,
    courseId: i,
    courseName: 'Expert Speaking',
    branch: 'Dhanmondi',
    relMgId: 'MG 105'+i,
    relMgName: 'Material of Listening',
    noOfFiles: 10,
    createdBy: 'Kazi Shahin',
  });
}

const materialDashboard = [];
for (let i = 0; i < 15; i++) {
  materialDashboard.push({
    id: i,
    fileId: i,
    fileName: 'Listening Group',
    fileType: 'Docx',
    collectFrom: 'Kazi Shahin',
    createdAt: '25 May, 2020',
    downloadAccess: true,
    speciality: true,
    usedFor: 'PTE 2 | PRACTICE 4 | MOCK 2'
  });
}





export default {
  wordList,
  stMaterialList,
  mainNavigation,
  groupMaterials,
  specialMaterials,
  courseWiseMaterials,
  materialDashboard
}